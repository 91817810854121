import Drawer from "@/components/Drawer.vue";
export default {
  name: "App",
  components: {
    Drawer
  },

  data() {
    return {
      flying: false,
      isDrawerOpen: false
    };
  },

  mounted() {
    window.addEventListener("scroll", e => {
      var posi = window.scrollY;

      if (posi > 190) {
        if (!this.flying) this.flying = true;
      }

      if (posi < 70) {
        this.flying = false;
      }
    });
  },

  methods: {
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },

    closeDrawer() {
      this.isDrawerOpen = false;
    }

  }
};