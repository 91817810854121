import "@splidejs/splide/dist/css/splide.min.css";
import { Field, Form } from "vee-validate";
import IconSlide1 from '@/components/Vectors/IconSlide_1.svg';
import IconSlide2 from '@/components/Vectors/IconSlide_2.svg';
import IconSlide3 from '@/components/Vectors/IconSlide_3.svg';
import IconSlide4 from '@/components/Vectors/IconSlide_4.svg';
import IconSlide5 from '@/components/Vectors/IconSlide_5.svg';
import IconSlideActive1 from '@/components/Vectors/IconSlideActive_1.svg';
import IconSlideActive2 from '@/components/Vectors/IconSlideActive_2.svg';
import IconSlideActive3 from '@/components/Vectors/IconSlideActive_3.svg';
import IconSlideActive4 from '@/components/Vectors/IconSlideActive_4.svg';
import IconSlideActive5 from '@/components/Vectors/IconSlideActive_5.svg';
export default {
  components: {
    Field,
    Form
  },
  data: function () {
    return {
      dialogState: false,
      step: 0,
      message: undefined,
      form: {},
      brands: [{
        text: this.$CD("CAROUSEL_TEXT1"),
        logo: IconSlide1,
        logoActive: IconSlideActive1,
        id: "brand1",
        type: "video",
        vimeoUrl: 'https://player.vimeo.com/video/727348357?h=a8a88a9482'
      }, {
        image: "/images/carousel/brand3.jpg",
        text: this.$CD("CAROUSEL_TEXT2"),
        logo: IconSlide2,
        logoActive: IconSlideActive2,
        id: "brand2",
        type: "image",
        images: ['/images/carousel/1.jpg', '/images/carousel/2.jpg', '/images/carousel/3.jpg', '/images/carousel/4.jpg']
      }, {
        text: this.$CD("CAROUSEL_TEXT3"),
        logo: IconSlide3,
        logoActive: IconSlideActive3,
        id: "brand3",
        type: "video",
        vimeoUrl: 'https://player.vimeo.com/video/704483672'
      }, {
        text: this.$CD("CAROUSEL_TEXT4"),
        logo: IconSlide4,
        logoActive: IconSlideActive4,
        id: "brand4",
        type: "image",
        images: ['/images/carousel/5.jpg', '/images/carousel/6.jpg', '/images/carousel/7.jpg', '/images/carousel/8.jpg']
      }, {
        image: "/images/carousel/brand3.jpg",
        image_mobile: "/images/carousel/brand3-mobile.jpg",
        text: this.$CD("CAROUSEL_TEXT5"),
        logo: IconSlide5,
        logoActive: IconSlideActive5,
        id: "brand5",
        type: "image",
        images: ['/images/carousel/9.jpg', '/images/carousel/10.jpg']
      }],
      thumbsOptions: {
        type: "slide",
        rewind: true,
        gap: "25px",
        pagination: false,
        width: 475,
        fixedWidth: 75,
        fixedHeight: 85,
        breakpoints: {
          768: {
            perPage: 2,
            width: 400,
            fixedWidth: 60,
            fixedHeight: 85
          },
          578: {
            gap: '25px',
            perPage: 2,
            width: 300,
            fixedWidth: 40,
            fixedHeight: 85
          }
        },
        perPage: 4,
        focus: 1,
        isNavigation: true,
        updateOnMove: true
      }
    };
  },

  mounted() {
    setTimeout(() => {
      const main = this.$refs.main;
      const thumbs = this.$refs.thumbs;
      const thumbsSplide = thumbs.splide;

      if (thumbsSplide) {
        main.sync(thumbsSplide);
      }
    }, 1000);
  },

  methods: {
    isRequired(value) {
      return value ? true : "Ce champ est requit";
    },

    validateEmail(value) {
      if (!value) {
        return "Ce champ est requit";
      }

      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!regex.test(value)) {
        return "Vous devez entrer un email valide";
      }

      return true;
    },

    onSubmit(values) {
      this.dialogState = true;
      var formDatas = {
        nom: values.nom,
        email: values.email,
        objet: values.objet,
        message: this.message
      };
      this.axios.post("https://api.lucien.ai/api/operations-custom/promodev-send-contact/sendmail", formDatas).then(response => {
        console.log(response.data);
      });
    }

  }
};