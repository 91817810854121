import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_click_away = _resolveDirective("click-away");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(["drawer", {
      'is-open': $props.isOpen,
      'is-visible': $data.isVisible
    }])
  }, [_createElementVNode("div", {
    class: "drawer__overlay",
    style: _normalizeStyle({
      transitionDuration: `${$props.speed}ms`
    })
  }, null, 4), _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "drawer__content",
    style: _normalizeStyle({
      maxWidth: $props.maxWidth,
      transitionDuration: `${$props.speed}ms`,
      backgroundColor: $props.backgroundColor
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 4)), [[_directive_click_away, $options.closeDrawer]])], 2)]);
}