import copydeck from './copydeck.csv'

let language = 'fr'
if(localStorage.lang ) language = localStorage.lang

var url_string = window.location.href
var url = new URL(url_string);
var lang = url.searchParams.get("lang");

if(lang && (lang==='fr' || lang ==='en')) {
    localStorage.lang = lang;
    language = lang
    window.location.href = "/";

}

export default {
    get(KEY, variables){
  
        var __FOUND = copydeck.find(function(cd, index) {
            if(cd[0] == KEY) {
                return true;
            }  
        });

               
        if(__FOUND) {
            let VALUE;
            if(language ==='fr' && __FOUND[1] != null) VALUE = __FOUND[1].trim()
            else if(language ==='en' && __FOUND[2] != null) VALUE = __FOUND[2].trim()

            if(VALUE) {
         
                  if(variables) {
                    for (let i = 0; i < variables.length; i++) {
                        const variable = variables[i];
                        VALUE = VALUE.replace(`$${i}`, variable);
                    }                   
                }
                return VALUE;
            } else {
                return KEY;
            }
        } else {
           console.log("COPYDECK MISSING TRANSLATION", KEY, language);
           return KEY;
        }      
    },
    setLang(lang) {
        if (lang==='fr' || lang ==='en' || lang ==='it' || lang ==='de' || lang ==='es') {
            language = lang;
            localStorage.lang = lang;
        }
    }
}