import { directive } from "vue3-click-away";
export default {
  name: "Drawer",
  directives: {
    ClickAway: directive
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    maxWidth: {
      type: String,
      required: false,
      default: "400px"
    },
    // Transition Speed in Milliseconds
    speed: {
      type: Number,
      required: false,
      default: 300
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fafafa"
    }
  },

  data() {
    return {
      isVisible: false,
      isTransitioning: false
    };
  },

  watch: {
    isOpen(val) {
      this.isTransitioning = true;

      if (val) {
        this.toggleBackgroundScrolling(true);
        this.isVisible = true;
      } else {
        this.toggleBackgroundScrolling(false);
        setTimeout(() => this.isVisible = false, this.speed);
      }

      setTimeout(() => this.isTransitioning = false, this.speed);
    }

  },
  methods: {
    toggleBackgroundScrolling(enable) {
      const body = document.querySelector("body");
      body.style.overflow = enable ? "hidden" : null;
    },

    closeDrawer() {
      if (!this.isTransitioning) {
        this.$emit("close");
      }
    }

  },

  mounted() {
    this.isVisible = this.isOpen;
  }

};