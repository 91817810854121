import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "contenair"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_RouterView = _resolveComponent("RouterView");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Header), _createVNode(_component_RouterView), _createVNode(_component_Footer)]);
}