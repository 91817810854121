export default {
  name: "foot",

  data() {
    return {
      totop: false
    };
  },

  mounted() {
    window.addEventListener("scroll", e => {
      var posi = window.scrollY;

      if (posi > 300) {
        if (!this.totop) this.totop = true;
      }

      if (posi < 200) {
        this.totop = false;
      }
    });
  }

};